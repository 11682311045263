@use '../../ui/color';
@use '../../ui/typography';

mat-form-field.mat-form-field-type-mat-input {
  & > div.mat-form-field-wrapper {
    margin: 0;

    & > div.mat-form-field-flex {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 0;
      padding-right: 0;
      margin-top: 0;
      background: color.$white;

      & > div.mat-form-field-outline {
        top: 0;

        & > div.mat-form-field-outline-start {
          border: 1px solid color.$grey-70;
          border-right: none;
          border-radius: 0;
        }

        & > div.mat-form-field-outline-gap {
          border: 1px solid color.$grey-70;
          border-left: none;
          border-right: none;
          border-radius: 0;
        }

        & > div.mat-form-field-outline-end {
          border: 1px solid color.$grey-70;
          border-left: none;
          border-radius: 0;
        }
      }

      & > div.mat-form-field-infix {
        padding: 0;
        border: none;

        & > input {
          @include typography.meta-regular;
          color: color.$grey-70;
          font-size: 14px;
          line-height: 48px;
          width: calc(100% - 48px);
          padding-left: 24px;
          padding-right: 24px;
          margin-top: 0;

          &::placeholder {
            color: color.$grey-40;
          }
        }

        & > textarea {
          @include typography.meta-regular;
          color: color.$grey-70;
          font-size: 14px;
          line-height: initial;
          width: calc(100% - 48px);
          height: 5em;
          padding: 1em 24px 1em 24px;
          margin: 0;
        }

        & > span.mat-form-field-label-wrapper {
          top: 0;
          padding-top: 0;
        }
      }

      & > div.mat-form-field-suffix {
        position: absolute;
        right: 1px;
        top: 2px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 46px;
        height: 44px;
        background: color.$white;

        /*
         * Although icons are typically 18px wide and offset by 12px from the right margin,
         * the 46px width is used above (rather than the logical 42px) for the sole purpose
         * of hiding the eye icon injected into password fields by the browser. This is also
         * the reason for the margin immediately below. Height of 44px and top offset of 2px
         * is to prevent overlapping of input border when scaled views result in floating-point
         * values for the input height.
         */

        & > i {
          margin-right: 12px;

          & > img {
            filter: color.$filter-grey-70;
          }

          &.button {
            cursor: pointer;
          }
        }

        & > span.mat-select-arrow {
          width: 24px;
          height: 24px;
          margin: 0;
          background: url('../../../assets/images/icons/chevron-down.svg')
            no-repeat center center;
          border: none;
          filter: color.$filter-grey-70;
        }
      }
    }

    & > div.mat-form-field-subscript-wrapper {
      padding: 0;
      margin-top: 6px;
      position: relative;

      & > div.mat-form-field-hint-wrapper {
        & > mat-hint {
          @include typography.meta-regular;
          color: color.$grey-70;
          font-size: 10px;
        }
      }

      & mat-error.mat-error {
        @include typography.meta-regular;
        color: color.$red-50;
        font-size: 10px;
      }
    }
  }

  &.mat-focused {
    & > div.mat-form-field-wrapper {
      & > div.mat-form-field-flex {
        & > div.mat-form-field-outline {
          & > div.mat-form-field-outline-start {
            border: 1px solid color.$brown-40;
            border-right: none;
          }

          & > div.mat-form-field-outline-gap {
            border: 1px solid color.$brown-40;
            border-left: none;
            border-right: none;
          }

          & > div.mat-form-field-outline-end {
            border: 1px solid color.$brown-40;
            border-left: none;
          }
        }

        & > div.mat-form-field-suffix {
          & > i {
            & > img {
              filter: color.$filter-brown-40;
            }
          }

          & > span.mat-select-arrow {
            filter: color.$filter-brown-50;
          }
        }
      }
    }
  }

  &.mat-form-field-disabled {
    & > div.mat-form-field-wrapper {
      & > div.mat-form-field-flex {
        & > div.mat-form-field-outline {
          & > div.mat-form-field-outline-start {
            border: 1px solid color.$grey-40;
            border-right: none;
          }

          & > div.mat-form-field-outline-gap {
            border: 1px solid color.$grey-40;
            border-left: none;
            border-right: none;
          }

          & > div.mat-form-field-outline-end {
            border: 1px solid color.$grey-40;
            border-left: none;
          }
        }

        & > div.mat-form-field-infix {
          & > input {
            //color: color.$grey-40;
          }
        }

        & > div.mat-form-field-suffix {
          & > i {
            & > img {
              filter: color.$filter-grey-40;
            }
          }
        }
      }
    }
  }

  &.mat-form-field-invalid,
  &.custom-form-error-state-active {
    & > div.mat-form-field-wrapper {
      & > div.mat-form-field-flex {
        & > div.mat-form-field-outline {
          & > div.mat-form-field-outline-start {
            border: 1px solid color.$red-50;
            border-right: none;
          }

          & > div.mat-form-field-outline-gap {
            border: 1px solid color.$red-50;
            border-left: none;
            border-right: none;
          }

          & > div.mat-form-field-outline-end {
            border: 1px solid color.$red-50;
            border-left: none;
          }
        }

        & > div.mat-form-field-suffix {
          & > i {
            & > img {
              filter: color.$filter-red-50;
            }
          }
        }
      }

      & > div.mat-form-field-subscript-wrapper {
        & > div.mat-form-field-hint-wrapper {
          & > mat-hint {
            color: color.$red-50;
          }
        }
      }
    }
  }

  &.content-width {
    width: max-content;
  }

  &.no-leading {
    & > div.mat-form-field-wrapper {
      padding: 0;
    }
  }
}
