@use '../../ui/color';
@use '../../ui/typography';

.highcharts-pie-series .highcharts-point-hover {
  fill-opacity: 1;
}

.highcharts-plot-line {
  stroke: color.$grey-70;
}

.highcharts-no-data text {
  @include typography.meta-regular;
  fill: color.$grey-40;
  font-size: 14px;
  font-weight: normal;
}

div.highcharts-container {
  & > svg {
    @include typography.meta-regular;
    font-size: 12px;

    & > g.highcharts-axis-labels {
      color: color.$grey-70;
      font-size: 12px;
    }

    & .chart-tooltip-bullet {
      filter: color.$filter-brown-40;
    }

    & .highcharts-tooltip-box {
      stroke-width: 0;
      fill: color.$grey-30;
      fill-opacity: 1;
    }
  }

  &.wmp-line-chart {
    & > svg {
      & > g.highcharts-series-group {
        & > g.highcharts-series {
          &.highcharts-color-0 {
            fill: color.$brown-50;
            stroke: color.$brown-50;
          }
        }
      }
    }
  }

  &.wmp-posPriceLine-chart {
    & > svg {
      & > rect.highcharts-background {
        fill: color.$grey-20;
      }
      & > g.highcharts-axis {
        & > path.highcharts-axis-line {
          stroke: #aeaeae;
        }
      }
      & > g.highcharts-series-group {
        & > g.highcharts-series {
          &.highcharts-color-0 {
            fill: color.$brown-50;
            stroke: color.$brown-50;
          }
        }
      }
    }
  }

  &.wmp-donut-chart {
    & > svg {
      & > g.highcharts-series-group {
        & > g.highcharts-series {
          & > path {
            &.asset-class-cash {
              fill: color.$yellow-50;
            }

            &.asset-class-bonds {
              fill: color.$blue-40;
            }

            &.asset-class-equities {
              fill: color.$brown-50;
            }

            &.asset-class-alternative {
              fill: color.$green-60;
            }

            &.asset-class-mixed {
              fill: color.$grey-50;
            }

            &.asset-class-taxes {
              fill: color.$grey-50;
            }

            &.asset-region-europe {
              fill: color.$grey-54;
            }

            &.asset-region-usa {
              fill: color.$grey-53;
            }

            &.asset-region-asia {
              fill: color.$grey-52;
            }

            &.asset-region-worldwide {
              fill: color.$grey-51;
            }
          }
        }
      }
    }
  }

  &.wmp-bar-chart {
    & > svg {
      & > g.highcharts-series-group {
        & > g.highcharts-series {
          & > rect {
            &.highcharts-color-0 {
              fill: color.$grey-30;
              stroke: none;
            }

            &.asset-class-cash {
              fill: color.$yellow-50;
              stroke: none;
            }

            &.asset-class-bonds {
              fill: color.$blue-40;
              stroke: none;
            }

            &.asset-class-equities {
              fill: color.$brown-50;
              stroke: none;
            }

            &.asset-class-alternative {
              fill: color.$green-60;
              stroke: none;
            }

            &.asset-class-mixed {
              fill: color.$grey-50;
              stroke: none;
            }

            &.asset-class-taxes {
              fill: color.$grey-50;
              stroke: none;
            }

            &.asset-region-europe {
              fill: color.$grey-54;
              stroke: none;
            }

            &.asset-region-usa {
              fill: color.$grey-53;
              stroke: none;
            }

            &.asset-region-asia {
              fill: color.$grey-52;
              stroke: none;
            }

            &.asset-region-worldwide {
              fill: color.$grey-51;
              stroke: none;
            }
          }
        }
      }
    }

    &.portfolio-currencies {
      & > svg {
        & > g.highcharts-xaxis-labels {
          & > text {
            fill: color.$brown-50;
          }
        }
      }
    }

    &.portfolio-sectors {
      & > svg {
        & > g.highcharts-xaxis-labels {
          & > text {
            fill: color.$brown-50;
          }
        }
      }
    }
  }
}

div.highcharts-tooltip-container {
  @include typography.meta-regular;
  font-size: 12px;

  & > svg {
    & > g.highcharts-tooltip {
      fill: none;
      stroke: none;

      & > path.highcharts-tooltip-box {
        fill: color.$grey-30;
      }
    }
  }
}
