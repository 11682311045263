@use '../../ui/color';
@use '../../ui/spacing';
@use '../../ui/typography';

/*
 * RMP document upload
 */

div.cdk-overlay-pane.upload-document {
  width: 900px !important;

  & > mat-dialog-container {
    padding: spacing.$xl spacing.$m spacing.$xl spacing.$m;
    border-radius: 0;
    background-color: color.$white;
  }
}
