@use '../../ui/color';
@use '../../ui/spacing';
@use '../../ui/typography';

/*
 * Autocomplete
 */

div.cdk-overlay-pane {
  & > div.mat-autocomplete-panel {
    border: 1px solid color.$grey-30;
    border-top: 1px solid color.$brown-40;
    border-radius: 0;
    box-shadow: none;
    background-color: color.$white;

    & > mat-option {
      padding-left: 24px;
      padding-right: 24px;

      & > span.mat-option-text {
        @include typography.meta-regular;
        color: color.$grey-70;
        font-size: 14px;
      }

      &[aria-disabled='true'] {
        & > span.mat-option-text {
          color: color.$grey-40;
        }
      }

      &:not([aria-disabled='true']):hover {
        background: color.$grey-20;

        & > span.mat-option-text {
          color: color.$brown-50;
        }
      }

      &.mat-active {
        background: none;
      }
    }
  }
}
