@use '../../ui/color';
@use '../../ui/typography';

/*
 * Document preview modal
 */

div.cdk-overlay-pane.document-preview {
  width: 100%;

  & > mat-dialog-container {
    padding: 0;
    border-radius: 0;
    background-color: color.$white;

    & pdf-paging-area {
      display: flex;

      & > div.paging-left,
      div.paging-right {
        display: flex;
      }

      & > pdf-page-number {
        display: flex;
        align-items: center;
      }
    }
  }
}
