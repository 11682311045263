/*
 * Generic icon rules declared here.
 * Be sure to check component-specific stylesheets for more granular rules, specifically custom dimensions.
 */
@use './color';

div {
  & > a:link {
    color: color.$brown-50;
    background-color: transparent;
    text-decoration: none;
  }

  & > a:visited {
    color: color.$brown-50;
    background-color: transparent;
    text-decoration: none;
  }

  & > a:hover {
    color: color.$brown-50;
    background-color: transparent;
    text-decoration: underline;
  }

  & > a:active {
    color: color.$brown-50;
    background-color: transparent;
    text-decoration: underline;
  }
}
