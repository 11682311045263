@use '../../ui/color';
@use '../../ui/spacing';
@use '../../ui/typography';

/*
 * Export CSV modal
 */

div.cdk-overlay-pane.export-csv-modal {
  max-width: 696px !important;

  & > mat-dialog-container {
    padding: spacing.$xl spacing.$m spacing.$xl spacing.$m;
    border-radius: 0;
    background-color: color.$white;
  }
}
