@use './color';

table.data-table {
  & span.mdi {
    position: relative;
    padding-left: 24px;

    &:before {
      font-size: 24px;
      position: absolute;
      left: 0;
      bottom: -9px;
    }

    &.positive {
      &:before {
        color: color.$green-40;
      }
    }

    &.negative {
      &:before {
        color: color.$brown-45;
      }
    }
  }
}
