@use './color';
@use './typography';

.dropzoneStyle {
  border-radius: 0% !important;
  border: 1px solid #262626 !important;
  height: 100px;
  margin: auto;
}

.dropzoneStyleDisabled {
  border-radius: 0% !important;
  border: 1px solid color.$grey-40 !important;
  height: 100px;
  margin: auto;
}

.dropContentStyle {
  @include typography.meta-regular;
  font-size: 14px;
  color: color.$grey-40;
  font-size: 14px;
  line-height: 48px;
  width: 100%;
  padding-left: 24px;
  padding-right: 12px;
  margin-top: 0;
}

.dropButtonStyle {
  color: color.$grey-40;
  text-decoration: underline;
  width: 0px;
  display: inline;
}

.dropDelButtonStyle {
  width: 18px;
  height: 18px;
  display: inline-block;
  float: right;

  & > img {
    height: 16px;
  }
}

.dropImageStyleDisabled {
  filter: color.$filter-grey-40;
}

.dropzoneDisabledLabelStyle {
  margin-left: 6px;
  margin-right: 6px;
}

.dropzoneLabelStyle {
  margin-left: 6px;
  margin-right: 6px;
  color: color.$black;
}
