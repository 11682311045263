@use './color';
@use './spacing';
@use './typography';

table {
  width: 100%;

  & th {
    @include typography.meta-bold;
  }

  & td {
    @include typography.meta-regular;

    &.symbol {
      position: relative;
      padding-left: 24px;

      &:before {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: cover;
        transform: translate(-22px, 4px);
      }

      &.positive {
        &:before {
          background-image: url('../../assets/images/icons/arrow-top-right.svg');
          filter: color.$filter-green-50;
        }
      }

      &.negative {
        &:before {
          background-image: url('../../assets/images/icons/arrow-bottom-right.svg');
          filter: color.$filter-red-50;
        }
      }
    }
  }

  & th,
  td {
    color: color.$grey-70;
    font-size: 14px;
    vertical-align: middle;
    padding: spacing.$xs 0 spacing.$xs 0;
    margin: 0;
    border: none;
    border-bottom: 1px solid color.$grey-30;
  }

  &.no-bottom-border {
    & tr:last-of-type {
      & > td {
        border-bottom: none;
      }
    }
  }
}
