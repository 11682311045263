@use '../../ui/color';
@use '../../ui/spacing';
@use '../../ui/typography';

/*
 * Generic WMP modal
 */

div.cdk-overlay-pane.logout {
  max-width: 620px !important;

  & > mat-dialog-container {
    padding: spacing.$xl spacing.$m spacing.$xl spacing.$m;
    border-radius: 0;
    background-color: color.$white;
  }
}
