@use '../../ui/color';
@use '../../ui/typography';

/*
 * Navigation header portfolio selector customisation
 */

// Select
mat-form-field.mat-form-field-type-mat-select {
  &.navigation-header-portfolio-selector {
    width: 300px;

    & > div.mat-form-field-wrapper {
      & > div.mat-form-field-flex {
        background: color.$white;

        & > div.mat-form-field-infix {
          & > mat-select {
            & > div.mat-select-trigger {
              & > div.mat-select-value {
                & > span.mat-select-value-text {
                  & > span.mat-select-min-line {
                    @include typography.meta-bold;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Dropdown
div.cdk-overlay-pane {
  & div.mat-select-panel {
    &.navigation-header-portfolio-selector {
      min-width: 0 !important;
      max-width: none;
      width: 300px;
      border: 1px solid color.$brown-40 !important;
      background-color: color.$white;
    }
  }
}
