@use '../../ui/color';
@use '../../ui/spacing';
@use '../../ui/typography';

/*
 * Generic WMP modal
 */

div.cdk-overlay-pane.generic-modal {
  width: 620px !important;

  & > mat-dialog-container {
    padding: spacing.$l spacing.$m spacing.$l spacing.$m;
    border-radius: 0;
    background-color: color.$white;
  }
}
