@use '../../ui/color';
@use '../../ui/typography';

/*
 * Account header product selector customisation
 */

$offset-width: 12px;

// Select
mat-form-field.mat-form-field-type-mat-select {
  &.account-header-product-selector {
    display: block;
    width: calc(100% + ($offset-width * 2));
    margin-top: -$offset-width;
    margin-right: -$offset-width;
    margin-bottom: 0;
    margin-left: -$offset-width;

    & > div.mat-form-field-wrapper {
      & > div.mat-form-field-flex {
        & > div.mat-form-field-infix {
          width: 100%;

          & > mat-select {
            & > div.mat-select-trigger {
              padding-left: 0;
              padding-right: 0;

              & > div.mat-select-value {
                overflow: visible;

                & > span.mat-select-value-text {
                  margin-left: -4px;
                  margin-right: -4px;

                  & > span.mat-select-min-line {
                    @include typography.meta-bold;
                    font-size: 16px;
                  }
                }
              }
            }

            &:before {
              border: none;
              width: calc(
                100% + 32px
              ); // Here 32px to account for the 32px programmatically added inline by Material ಠ_ಠ
            }

            &[aria-expanded='true'] {
              &:before {
                border: 1px solid color.$grey-30;
              }
            }
          }
        }
      }
    }
  }
}

// Dropdown
div.cdk-overlay-pane {
  &.account-header-product-selector {
    div.mat-select-panel-wrap {
      width: 100%;

      & > div.mat-select-panel {
        border-top: 1px solid color.$grey-30;
        background-color: color.$white;

        & > mat-option {
          & + mat-option {
            border-top: 1px solid color.$grey-30;
          }
        }
      }
    }
  }
}
