@use './color';
@use './spacing';
@use './typography';

$cell-gutter-width: spacing.$l;

table.accordion-table {
  & > thead {
    & th {
      font-size: 15px;
      vertical-align: bottom;
      white-space: nowrap;
      padding-left: $cell-gutter-width * 0.5;
      padding-right: $cell-gutter-width * 0.5;
      border-bottom: 1px solid color.$grey-70;

      &:first-child {
        padding-left: $cell-gutter-width * 0.25;
      }

      &:last-child {
        padding-right: 34px;
      }
    }
  }

  & > tbody {
    & > tr {
      &.accordion-header {
        cursor: pointer;

        & > td {
          position: relative;
          padding-left: $cell-gutter-width * 0.5;
          padding-right: $cell-gutter-width * 0.5;

          &:first-child {
            padding-left: $cell-gutter-width * 0.25;
          }

          &:last-child {
            position: relative;
            padding-right: 34px;

            &:after {
              content: '';
              position: absolute;
              top: 50%;
              right: 12px;
              display: block;
              width: 12px;
              height: 12px;
              background: url('../../assets/images/icons/chevron-down.svg')
                no-repeat center center;
              background-size: 12px;
              transform: translateY(-50%);
              filter: color.$filter-black;
            }
          }
        }

        &.open {
          & > td {
            &:last-child {
              &:after {
                background-image: url('../../assets/images/icons/chevron-up.svg');
              }
            }
          }
        }
      }

      &.accordion-content {
        & > td {
          padding-top: 0;
          padding-bottom: 0;
          border-bottom: none;

          & > div.content-panel {
            height: 0;
            margin-top: -1px;
            overflow: hidden;
            background: color.$grey-20;
            box-shadow: 0 0 0 1px color.$grey-30 inset;
            transition: height 0.35s ease-out;

            & > table {
              & > tbody {
                & > tr {
                  & > td {
                    width: 50%;
                    height: auto;
                    padding: 0 ($cell-gutter-width * 0.25) 0
                      ($cell-gutter-width * 0.25);
                    border-bottom: none;

                    & > div.row {
                      align-items: flex-start;
                      height: 100%;
                      margin: 0;
                      border-top: 1px solid color.$grey-30;

                      & > div.col {
                        display: flex;
                        align-items: center;
                        padding: 10px 0 10px 0;

                        &:first-child {
                          padding-right: $cell-gutter-width * 0.25;
                        }

                        &:last-child {
                          padding-left: $cell-gutter-width * 0.25;
                        }
                      }

                      & > div.col-description {
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        padding: 10px 0 10px 0;

                        &:first-child {
                          padding-right: $cell-gutter-width * 0.25;
                        }

                        &:last-child {
                          padding-left: $cell-gutter-width * 0.25;
                        }
                      }

                      &.utility {
                        border-top: none;

                        & > div.col {
                          justify-content: flex-end;
                          padding-left: 0;
                          padding-right: 0;

                          & > button {
                            & + button {
                              margin-left: spacing.$s;
                            }
                          }
                        }
                      }
                    }
                  }

                  &:first-child {
                    & > td {
                      & > div.row {
                        border-top: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
