@use './color';
@use './typography';

$cell-gutter-width: 48px;

table.utility-table {
  & > thead {
    & th {
      font-size: 15px;
      padding-left: $cell-gutter-width * 0.5;
      padding-right: $cell-gutter-width * 0.5;
      border-bottom: 1px solid color.$grey-70;

      &:first-child {
        padding-left: $cell-gutter-width * 0.25;
      }

      &:last-child {
        padding-right: $cell-gutter-width * 0.25;
      }
    }
  }

  & > tbody {
    & > tr {
      & > td {
        padding-left: $cell-gutter-width * 0.5;
        padding-right: $cell-gutter-width * 0.5;
        padding-top: $cell-gutter-width * 0.25;
        padding-bottom: $cell-gutter-width * 0.25;

        &:first-child {
          padding-left: $cell-gutter-width * 0.25;
        }

        &:last-child {
          padding-right: $cell-gutter-width * 0.25;
        }
      }
    }
  }
}
