@use './color';

// Fonts
@mixin meta-regular {
  font-family: 'Meta_book', sans-serif;
  font-weight: normal;
  font-variant-numeric: lining-nums;
}

@mixin meta-bold {
  font-family: 'Meta_bold', sans-serif;
  font-weight: normal;
  font-variant-numeric: lining-nums;
}

@mixin stempel-garamond-regular {
  font-family: 'Stempel_Garamond';
  font-weight: normal;
  font-variant-numeric: lining-nums;
}

// Headline 1
@mixin headline1 {
  @include stempel-garamond-regular;
  color: color.$grey-70;
  font-size: 34px;
  line-height: normal;
  margin: 0;
}

h1,
.headline1 {
  @include headline1;
}

// Headline 2
@mixin headline2 {
  @include stempel-garamond-regular;
  color: color.$grey-70;
  font-size: 22px;
  line-height: normal;
  margin: 0;
}

h2,
.headline2 {
  @include headline2;
}

// Subhead
@mixin subhead {
  @include meta-bold;
  color: color.$grey-70;
  font-size: 16px;
  line-height: normal;
  margin: 0;
}

h3,
.subhead {
  @include subhead;
}

// Copy
@mixin copy {
  @include meta-regular;
  color: color.$grey-70;
  font-size: 14px;
  line-height: normal;
}

p,
.copy {
  @include copy;
}

// Bold copy
strong,
.copy.bold {
  @include meta-bold;
  color: color.$grey-70;
  font-size: 14px;
  line-height: normal;
}

// Overline
@mixin overline {
  @include copy;
  font-size: 12px;
  text-transform: uppercase;
}

.overline {
  @include overline;

  & + h3 {
    margin-top: 6px;
  }
}

// Infotext
@mixin infotext {
  @include copy;
  font-size: 10px;
}

.infotext {
  @include infotext;
}

// Hyperlink
// Only here because I need it to style a tag injected from the i18n dictionaries which are otherwise outside of the component's view encapsulation.
.hyperlink {
  font-size: inherit;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
