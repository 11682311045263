@use '../ui/color';
@use '../ui/spacing';
@use '../ui/typography';

div.alert {
  // @include typography.meta-bold;
  color: color.$white;
  font-size: 14px;
  padding: spacing.$xs spacing.$s spacing.$xs spacing.$s;
  margin-bottom: spacing.$s;
  border: 0;
  border-radius: 0;

  &.alert-danger {
    background-color: color.$red-50;
  }

  &.alert-success {
    background: color.$green-50;
  }
}
