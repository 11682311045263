@use '../../ui/color';
@use '../../ui/typography';

// Dropdown
div.cdk-overlay-pane {
  & > div.mat-select-panel-wrap {
    & > div.mat-select-panel {
      &[aria-multiselectable='true'] {
        min-width: calc(100% + 32px) !important;
        transform: scaleY(1) translateX(24px) !important;
      }
      background-color: color.$white;

      & > mat-option {
        & > mat-pseudo-checkbox.mat-pseudo-checkbox {
          margin: 0 10px 0 0;
          background: none;
          border: none;
          border-radius: 0;
          opacity: 0;

          &:after {
            width: 10px;
            height: 10px;
            background: url('../../../assets/images/icons/check.svg') no-repeat
              center center;
            background-size: contain;
            border: none;
            transform: none;
            filter: color.$filter-brown-50;
          }
        }

        &[aria-selected='true'] {
          background: color.$grey-30;

          & > mat-pseudo-checkbox.mat-pseudo-checkbox {
            opacity: 1;
          }

          & > span.mat-option-text {
            color: color.$brown-50;
          }
        }
      }
    }
  }
}
