@use '../../ui/color';
@use '../../ui/typography';
@use '../../ui/spacing';

mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
  border-radius: 0;
  border: 1px solid #c2542b;

  & > mat-button-toggle.mat-button-toggle-checked {
    & > button.mat-button-toggle-button {
      color: #ffffff;
      background: #c2542b;
    }
  }

  & > mat-button-toggle {
    & > button.mat-button-toggle-button {
      color: #c2542b;
      min-width: spacing.$l;

      & > span {
        @include typography.meta-regular;
        font-size: 14px;
        line-height: 48px;
      }
    }
  }
}
