$brown-50: #c2542b;
$brown-45: #b05000;
$brown-40: #a54724;
$brown-60: #e7bbaa;
$green-40: #00b050;
$green-50: #00843c;
$green-60: #559175;
$red-50: #e60000;
$red-60: #f27373;
$black: #000000;
$white: #ffffff;
$grey-20: #f5f5f5;
$grey-30: #e7e7e7;
$grey-40: #686868;
$grey-50: #9e9a97;
$grey-51: #dad9d8;
$grey-52: #bab7b4;
$grey-53: #9d9a97;
$grey-54: #75726f;
$grey-60: #495057;
$grey-70: #262626;
$grey-80: #080808;
$blue-40: #576a85;
$yellow-50: #f8c019;
$overlay: rgba(0, 0, 0, 0.5);

// Filters
$filter-black: brightness(0) saturate(100%);
$filter-white: brightness(0) saturate(100%) invert(80%) sepia(100%)
  saturate(91%) hue-rotate(267deg) brightness(113%) contrast(101%);
$filter-brown-50: brightness(0) saturate(100%) invert(34%) sepia(54%)
  saturate(1362%) hue-rotate(343deg) brightness(97%) contrast(85%);
$filter-brown-40: brightness(0) saturate(100%) invert(36%) sepia(52%)
  saturate(849%) hue-rotate(332deg) brightness(84%) contrast(95%);
$filter-grey-70: brightness(0) saturate(100%) invert(14%) sepia(0%) saturate(0%)
  hue-rotate(242deg) brightness(99%) contrast(98%);
$filter-grey-40: invert(42%) sepia(5%) saturate(9%) hue-rotate(332deg)
  brightness(95%) contrast(90%);
$filter-grey-30: brightness(0) saturate(100%) invert(98%) sepia(0%)
  saturate(46%) hue-rotate(27deg) brightness(115%) contrast(81%);
$filter-grey-20: brightness(0) saturate(100%) invert(78%) sepia(0%)
  saturate(46%) hue-rotate(27deg) brightness(115%) contrast(81%);
$filter-white: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(4%)
  hue-rotate(144deg) brightness(104%) contrast(101%);
$filter-red-50: brightness(0) saturate(100%) invert(13%) sepia(90%)
  saturate(4838%) hue-rotate(3deg) brightness(92%) contrast(124%);
$filter-green-50: brightness(0) saturate(100%) invert(25%) sepia(57%)
  saturate(5087%) hue-rotate(145deg) brightness(90%) contrast(101%);

// Aliases for compatability, ideally not to be used.
$primary: $brown-50;
$primary-variant: $brown-40;
$accent: $brown-60;
$accent-variant: $brown-40;
$positive: $green-50;
$neutral: $grey-40;
$negative: $red-50;
$warning: $red-60;
$warning-variant: $red-50;
$header-background: $grey-80;
$grey: $grey-20;
$outline: $grey-30;
$layover: $overlay;
$disabled: $grey-40;
$standard: $grey-70;
$liquidity: $yellow-50;
$fixed-income: $blue-40;
$equities: $brown-50;
$alternative-investment: $green-60;
$mixed-funds: $grey-50;
$secondary: $grey-60;
$secondary-btn: $white;
$chart: $brown-50;
