@use '../../ui/color';
@use '../../ui/spacing';
@use '../../ui/typography';

mat-accordion.mat-accordion {
  & > mat-expansion-panel.mat-expansion-panel {
    background: none;
    border-bottom: 1px solid color.$grey-30;
    box-shadow: none;

    & > mat-expansion-panel-header.mat-expansion-panel-header {
      height: auto;
      padding: 0 18px 0 0;

      & > span {
        &.mat-content {
          & > mat-panel-title.mat-expansion-panel-header-title {
            @include typography.stempel-garamond-regular;
            color: color.$grey-70;
            font-size: 22px;
            display: flex;
            align-items: center;
            min-height: spacing.$xl;
          }
        }

        &.mat-expansion-indicator {
          transition: none;

          &:after {
            content: '';
            width: 18px;
            height: 18px;
            background: url('../../../assets/images/icons/chevron-down.svg')
              no-repeat center center;
            background-size: cover;
            border: none;
            transform: none;
            filter: color.$filter-grey-70;
          }
        }
      }

      &.mat-expansion-panel-header:hover {
        background: transparent;
      }

      &.mat-expanded {
        height: auto;

        & > span {
          &.mat-content {
            & > mat-panel-title.mat-expansion-panel-header-title {
              color: color.$brown-50;
            }
          }

          &.mat-expansion-indicator {
            &:after {
              filter: color.$filter-brown-50;
            }
          }
        }
      }
    }

    & > div.mat-expansion-panel-content {
      margin-bottom: 0;
      transition: margin-bottom 0.35s ease-out;

      & > div.mat-expansion-panel-body {
        padding: 0;

        & > div {
          @include typography.meta-regular;
          color: color.$grey-70;
          font-size: 14px;
        }
      }
    }

    &.mat-expanded {
      & > div.mat-expansion-panel-content {
        margin-bottom: spacing.$s;
      }
    }

    &.mat-expansion-panel-spacing {
      margin: 0;
    }

    &:last-of-type {
      border-radius: 0;
    }
  }
}
