@use '../../ui/color';
@use '../../ui/typography';

// Note: Core input styles are found in ./_input.scss
mat-form-field.mat-form-field-type-mat-input {
  & > div.mat-form-field-wrapper {
    & > div.mat-form-field-flex {
      & > div.mat-form-field-infix {
        & > input[readonly='true'] {
          //color: color.$grey-40;
        }
      }
      & > div.mat-form-field-suffix {
        & > mat-datepicker-toggle {
          & > button {
            & > svg {
              & > path {
                visibility: hidden;
              }
              background: url('../../../assets/images/icons/calendar.svg')
                no-repeat center center;
            }

            display: flex;
            align-items: center;
            justify-content: center;
            width: 46px;
            height: 46px;

            & > span.mat-button-wrapper,
            & > span.mat-button-focus-overlay {
              display: none;
            }
          }

          &.mat-datepicker-toggle-active {
            color: color.$brown-40;

            & > button {
              & > svg {
                filter: color.$filter-brown-40;
              }
            }
          }
        }
      }
    }
  }

  &.mat-focused {
    & > div.mat-form-field-wrapper {
      & > div.mat-form-field-flex {
        & > div.mat-form-field-suffix {
          & > mat-datepicker-toggle {
            color: color.$brown-40;

            & > button {
              & > svg {
                filter: color.$filter-brown-40;
              }
            }
          }
        }
      }
    }
  }

  &.mat-form-field-invalid {
    & > div.mat-form-field-wrapper {
      & > div.mat-form-field-flex {
        & > div.mat-form-field-suffix {
          & > mat-datepicker-toggle {
            color: color.$red-50;

            & > button {
              & > svg {
                filter: color.$filter-red-50;
              }
            }
          }
        }
      }
    }
  }

  &.mat-form-field-disabled {
    & > div.mat-form-field-wrapper {
      & > div.mat-form-field-flex {
        & > div.mat-form-field-suffix {
          & > mat-datepicker-toggle {
            & > button {
              & > svg {
                filter: color.$filter-grey-40;
              }
            }
          }
        }
      }
    }
  }
}

// Calendar
div.cdk-overlay-pane.mat-datepicker-popup {
  & > mat-datepicker-content {
    border: 1px solid color.$grey-30;
    border-radius: 0;
    box-shadow: none;
    background-color: color.$white;

    & > div.mat-datepicker-content-container {
      & > mat-calendar {
        height: auto;

        & > mat-calendar-header {
          & > div.mat-calendar-header {
            & > div.mat-calendar-controls {
              margin: 0;

              & > button {
                height: 40px;

                &.mat-calendar-period-button {
                  text-align: left;
                  width: auto;
                  padding-left: 4.7142857143%;
                  padding-right: 4.7142857143%;

                  & > span.mat-button-wrapper {
                    & > span {
                      @include typography.meta-regular;
                      color: color.$grey-70;
                      font-size: 14px;
                    }

                    & > svg {
                      fill: color.$grey-70;
                    }
                  }
                }
              }
            }
          }
        }

        & > div.mat-calendar-content {
          & > mat-month-view,
          mat-year-view,
          mat-multi-year-view {
            & > table {
              & th,
              td {
                border: none;
              }

              & > thead {
                & > tr {
                  & > th {
                    & > abbr {
                      text-decoration: none;
                    }
                  }
                }
              }

              & > tbody {
                & > tr {
                  & > td {
                    & > button {
                      & > div.mat-calendar-body-cell-content {
                        &.mat-calendar-body-selected {
                          background-color: color.$brown-50;
                        }
                      }

                      &.mat-calendar-body-active,
                      &:hover {
                        & div.mat-calendar-body-cell-content {
                          color: color.$white;
                          background-color: color.$brown-50;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
