@use './color';
@use './typography';

table.allocation-table {
  & th,
  td {
    @include typography.meta-bold;
    text-align: right;

    &:first-child {
      text-align: left;
    }
  }

  & th {
    @include typography.meta-regular;
    font-size: 12px;
    text-transform: uppercase;
    border-bottom: none;
  }

  & td {
    &:first-child {
      @include typography.meta-regular;

      &:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 12px;
      }

      &.asset-class-cash {
        &:before {
          background: color.$yellow-50;
        }
      }

      &.asset-class-bonds {
        &:before {
          background: color.$blue-40;
        }
      }

      &.asset-class-equities {
        &:before {
          background: color.$brown-50;
        }
      }

      &.asset-class-alternative {
        &:before {
          background: color.$green-60;
        }
      }

      &.asset-class-mixed {
        &:before {
          background: color.$grey-50;
        }
      }

      &.asset-class-taxes {
        &:before {
          background: color.$grey-50;
        }
      }
    }
  }
}
