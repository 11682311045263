@use '../../ui/color';
@use '../../ui/typography';

mat-form-field.mat-form-field-type-mat-input {
  & > div.mat-form-field-wrapper {
    & > div.mat-form-field-flex {
      & > div {
        &.mat-form-field-infix {
          & > input {
            &.autocomplete {
              & + span.mat-select-arrow {
                position: absolute;
                right: 12px;
                top: 50%;
                display: block;
                width: 12px;
                height: 12px;
                margin: 0;
                background: url('../../../assets/images/icons/chevron-down.svg')
                  no-repeat center center;
                background-size: cover;
                border: none;
                transform: translateY(-50%);
                filter: color.$filter-grey-70;
              }

              &[aria-expanded='true'] {
                & + span.mat-select-arrow {
                  background-image: url('../../../assets/images/icons/chevron-up.svg');
                }
              }
            }
          }
        }
      }
    }
  }

  &.mat-focused {
    & > div.mat-form-field-wrapper {
      & > div.mat-form-field-flex {
        & > div.mat-form-field-infix {
          & > input {
            &.autocomplete {
              & + span.mat-select-arrow {
                filter: color.$filter-brown-50;
              }
            }
          }
        }
      }
    }
  }

  &.mat-form-field-disabled {
    & > div.mat-form-field-wrapper {
      & > div.mat-form-field-flex {
        & > div.mat-form-field-infix {
          & > input {
            &.autocomplete {
              & + span.mat-select-arrow {
                filter: color.$filter-grey-40;
              }
            }
          }
        }
      }
    }
  }

  &.mat-form-field-invalid {
    & > div.mat-form-field-wrapper {
      & > div.mat-form-field-flex {
        & > div.mat-form-field-infix {
          & > input {
            &.autocomplete {
              & + span.mat-select-arrow {
                filter: color.$filter-red-50;
              }
            }
          }
        }
      }
    }
  }
}
