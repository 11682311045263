@use './color';
@use './typography';

form {
  & div.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    & > label {
      @include typography.meta-regular;
      color: color.$grey-70;
      font-size: 14px;
      margin-bottom: 12px;
    }
  }

  /*
  * Used to mimic mat-errors. Ideally this will be used verrrry rarely, but a valid example is
  * the start- and end-date validation message for the postbox document filter.
  */
  & div.wmp-error {
    @include typography.meta-regular;
    color: color.$red-50;
    font-size: 10px;
    line-height: 1.125;
    margin-top: 6px;

    // Used to fake columns on some dual-input controls, e.g. date and value ranges on the filters.
    &.short {
      width: 47%;
      margin-left: auto;
    }
  }
}
