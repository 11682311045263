@use '../../ui/color';
@use '../../ui/spacing';
@use '../../ui/typography';

mat-tab-group.mat-tab-group {
  & > mat-tab-header.mat-tab-header {
    padding: 0 0 spacing.$s 0;
    margin: 0 0 spacing.$l 0;
    border-bottom: 1px solid color.$grey-30;

    & > div {
      &.mat-elevation-z4 {
        box-shadow: none;
      }

      &.mat-tab-label-container {
        & > div.mat-tab-list {
          & > div.mat-tab-labels {
            & > div.mat-tab-label {
              min-width: auto;
              height: auto;
              padding: 0;
              opacity: 1;

              & > div.mat-tab-label-content {
                @include typography.meta-regular;
                color: color.$grey-70;
                font-size: 12px;
                text-decoration: none;
                text-transform: uppercase;
                display: block;

                &:after {
                  content: '';
                  display: block;
                  width: 0;
                  height: 1px;
                  background: color.$brown-50;
                  transition: width 0.35s ease-out;
                }

                &:hover {
                  &:after {
                    width: spacing.$l;
                  }
                }
              }

              & + div.mat-tab-label {
                margin-left: 80px;
              }

              &.mat-tab-label-active {
                & > div.mat-tab-label-content {
                  color: color.$brown-50;

                  &:after {
                    width: spacing.$l;
                  }
                }
              }
            }
          }

          & > mat-ink-bar {
            display: none;
          }
        }
      }
    }
  }

  & > div.mat-tab-body-wrapper {
    & > mat-tab-body.mat-tab-body {
      & > div.mat-tab-body-content {
        & > h1 {
          margin-bottom: spacing.$m;
        }
      }
    }
  }
}
