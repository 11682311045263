@use '../../ui/color';
@use '../../ui/typography';

/*
 * Button style selector for forms
 */

mat-form-field.mat-form-field-type-mat-select {
  &.button {
    width: auto;
    min-width: 120px;

    & > div.mat-form-field-wrapper {
      padding: 0;

      & > div.mat-form-field-flex {
        background: color.$brown-50;

        & > div.mat-form-field-infix {
          & > mat-select {
            &:before {
              border: none;
            }

            &[aria-expanded='true'] {
              width: calc(100% + 32px);
              margin-left: -16px;
              margin-right: -16px;
              padding-left: 16px;
              padding-right: 16px;
              background: color.$brown-40;

              &:before {
                border: none;
              }
            }

            & > div.mat-select-trigger {
              & > div.mat-select-value {
                color: color.$white;
              }

              & > div.mat-select-arrow-wrapper {
                & > div.mat-select-arrow {
                  filter: color.$filter-white;
                }
              }
            }
          }

          & > span.mat-form-field-label-wrapper {
            & > label.mat-form-field-label {
              & > mat-label {
                color: color.$white;
              }
            }
          }
        }
      }
    }

    &.mat-form-field-disabled {
      & > div.mat-form-field-wrapper {
        & > div.mat-form-field-flex {
          background: color.$grey-40;

          & > div.mat-form-field-infix {
            & > mat-select {
              &:before {
                border: none;
              }

              & > div.mat-select-trigger {
                & > div.mat-select-value {
                  & > span.mat-select-value-text {
                    & > span.mat-select-min-line {
                      color: color.$grey-30;
                    }
                  }
                }

                & > div.mat-select-arrow-wrapper {
                  & > div.mat-select-arrow {
                    &:after {
                      color: color.$grey-30;
                    }
                  }
                }
              }
            }

            & > span.mat-form-field-label-wrapper {
              & > label.mat-form-field-label {
                & > mat-label {
                  color: color.$grey-30;
                }
              }
            }
          }
        }
      }
    }
  }
}
