@use '../../ui/color';
@use '../../ui/typography';

mat-checkbox {
  & > label.mat-checkbox-layout {
    margin-bottom: 0;

    & > span.mat-checkbox-inner-container {
      & > span.mat-ripple {
        & > span.mat-ripple-element,
        & > div.mat-ripple-element {
          display: none;
        }
      }

      & > span.mat-checkbox-frame {
        border: 1px solid color.$grey-70;
        border-radius: 0;
      }
    }

    & > span.mat-checkbox-label {
      @include typography.meta-regular;
      color: color.$grey-70;
      font-size: 14px;
    }
  }

  // Checked
  &.mat-checkbox-checked {
    & > label.mat-checkbox-layout {
      & > span.mat-checkbox-inner-container {
        & > span.mat-checkbox-background {
          background: color.$brown-50;
        }
      }
    }
  }

  // Disabled
  &.mat-checkbox-disabled {
    & > label.mat-checkbox-layout {
      & > span.mat-checkbox-inner-container {
        & > span.mat-checkbox-frame {
          border: 1px solid color.$grey-40;
        }
      }

      & > span.mat-checkbox-label {
        color: color.$grey-40;
      }
    }
  }
}
