@use '../../ui/color';
@use '../../ui/typography';

mat-radio-group {
  & > mat-radio-button {
    &.mat-radio-button {
      display: block;
    }

    &.mat-radio-checked {
      & > label.mat-radio-label {
        & > span.mat-radio-container {
          & > span.mat-radio-outer-circle {
            border: 1px solid color.$brown-50;
          }

          & > span.mat-radio-inner-circle {
            background: color.$brown-50;
          }
        }
      }
    }

    &.mat-radio-disabled {
      & > label.mat-radio-label {
        & > span.mat-radio-container {
          & > span.mat-radio-outer-circle {
            border: 1px solid color.$grey-40;
          }

          & > span.mat-radio-inner-circle {
            background: color.$grey-40;
          }
        }
        & > span.mat-radio-label-content {
          color: color.$grey-40;
        }
      }
    }

    & > label.mat-radio-label {
      & > span.mat-radio-container {
        & > span.mat-radio-outer-circle {
          border: 1px solid color.$grey-70;
        }

        & > span.mat-ripple {
          & > span.mat-ripple-element,
          & > div.mat-ripple-element {
            display: none;
          }
        }
      }

      & > span.mat-radio-label-content {
        @include typography.meta-regular;
        color: color.$grey-70;
        font-size: 14px;
        padding-left: 12px;
      }
    }
  }
}
